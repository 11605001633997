<template>
  <q-card style="padding: 24px 40px 40px 24px; margin-top: 16px; margin-bottom: 100px;" class="row q-col-gutter-md">
    <div v-for="(item, i) in list" :key="i" class="col-4">
      <a :href="item.url" target="_blank">
        <div class="my-card" flat bordered>
          <div class="tile" horizontal>
              <q-card-section class="q-pt-xs">
                <div class="text-h5">{{ item.name }}</div>
                <div style="font-size: 14px; font-weight: 400; color: #666666; line-height: 24px;">
                  {{ item.desc }}
                </div>
              </q-card-section>
              <q-card-section class="col-5 flex justify-between">
                <div style="margin-top: 18px;">前往查询</div>
                <q-img
                  style="max-width: 80px;"
                  class="icon rounded-borders"
                  :src="item.imgUrl"
                />
              </q-card-section>
          </div>
        </div>
      </a>
    </div>
  </q-card>
</template>

<script>
const list = [
  {
    name: "商标近似查询",
    desc:
      "本查询按图形、文字等商标组成要素分别提供近似检索功能，用户可以自行检索在相同或类似商品上是否已有相同或近似的商标",
    imgUrl: "/imgs/icon1.png",
    url: "http://wcjs.sbj.cnipa.gov.cn/txnS01.do?b9La8sqW=RvRG8AlqEx5OAUP8G4PyQOXzUf5qi_EOUoIE3c6NSzkRxenT8wBZWWWoAjzoQ6gAwncE2lZC4C.1Z1HHg_Dul7jKkC4xrN6ZCCiR6xNIjQUifKHgV_alDa"
  },
  {
    name: "商标综合查询",
    desc: "用户可以按商标号、商标、申请人名称等方式，查询某一商标的有关信息",
    imgUrl: "/imgs/icon2.png",
    url: "http://wcjs.sbj.cnipa.gov.cn/txnS02.do?b9La8sqW=V8mmNqlqErWFV5R9_ybIxTfBId3hI4WH9ke.Agpl5IJjKJFqySmlWHRyNCvWIMSqpJjzNdsFFagFF5dCGDAhQ_ZMiF706ib9mG5RNcjPxPSoPurojKe7AA"
  },
  {
    name: "商标状态查询",
    desc: "用户可以通过商标申请号或注册号查询有关商标在业务流程中的状态",
    imgUrl: "/imgs/icon3.png",
    url: "http://wcjs.sbj.cnipa.gov.cn/txnS03.do?b9La8sqW=fEHwpalqEmN54m7OMkm_pVFSSoeGrC1PR6_JZlgbr15fub.xiwU6WcoALi4jN9o27c6nnbVIIl5Ld3XTD_j65RBAKMFDJtmLtVIMm5bTM7rELOIEFYdxAG"
  },
  {
    name: "商标公告查询",
    desc: "提供商标公告查询",
    imgUrl: "/imgs/icon4.png",
    url: "http://wsgg.sbj.cnipa.gov.cn:9080/tmann/annInfoView/homePage.html"
  },
  {
    name: "错误信息反馈",
    desc: "用户可以向商标局反馈有关错误信息",
    imgUrl: "/imgs/icon5.png",
    url: "http://wcjs.sbj.cnipa.gov.cn/txnS05.do?b9La8sqW=XfEtOGlqEl6QSr22sgQBt7zztasMxYLaor27l6yKjrPzxt1i5SyIWr.wZF3pFu93XjyB4mlblChMvDror6jkYZwC3subbEzSl8xcbo05OmVJxtgIHMUfhG"
  },
  {
    name: "商品及服务项目",
    desc: "提供商品及服务项目的查询",
    imgUrl: "/imgs/icon6.png",
    url: "http://wcjs.sbj.cnipa.gov.cn/txnS06.do?b9La8sqW=zDPmMqlqEJTftYP7FYMrNlctG8A3_x775AE4xMqjhakgJON31MuhWEuzxDzNhGD.ApQs86x2ZuKkGGHuWHkpOZ6CdHqudSY.Y0Afj6wdPl9TJKNVaR6WWa"
  }
];

export default {
  name: "ChainList",
  props: {
    msg: String
  },
  data() {
    return {
      list,
      itemName: null,
    };
  },
  mounted() {
    this.getIndustryList();
  },
  watch: {
    msg(val, oldVal) {
      console.log("msg: " + val, oldVal);
      this.getIndustryList();
    }
  },
  methods: {
    getChainId(v) {
      this.$router.push({ name: "PatDetail", params: { id: v } });
    },
    getIndustryList() {
      this.$http.get(this.$props.msg + ".json")
        .then(response => {
          this.itemName = response.data.name;
          this.list = response.data.list;
        })
        .catch(() => {})
    }
  }
};
</script>

<style scoped>
.my-card {
  width: 100%;
}
.my-card:hover {
  box-shadow: 0 0 0 1px rgb(198 28 28 / 20%);
  border-color: #c61c1c;
  transition: all 0.3s;
  cursor: pointer;
}
a {
  text-decoration: none;
  color: #333;
}
a:hover {
  color: #c61c1c;
}
.text-h5 {
  font-weight: 700;
}
.tile {
  padding: 24px;
  background: #F4F7FA;
  height: 253px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}
.text-h5 {
  color: #333333;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 18px;
}
.q-card__section--vert {
  padding: 0;
}
@media (max-width: 1350px) {
  .icon {
    display: none;
  }
}
</style>
